import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import React, { useState, useEffect } from "react";
import "../Assets/Css/Table.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { axiosConfig } from "../axiosAPI";
import { Alert, Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";

function FAQs() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const auth = useAuth();
  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        "https://api.wabell.net/api/v1/faqs",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      );
      setData(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  useEffect(() => {
    getData().then((data) => {
      setLoading(false);
    });
  }, []);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const headerElement2 = (
    <div className="d-flex align-items-center justify-content-between gap-2">
      <span className="font-bold white-space-nowrap">Edit FAQ</span>
    </div>
  );
  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Add FAQ</span>
    </div>
  );
  const footerContent = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => {
          handleSubmit();
          {!error ? setVisible(true) : setVisible(false)}
        }}
        className="save"
        autoFocus
      />
    </div>
  );
  const footerContent2 = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible2(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => {
          editUser();
          setVisible2(false);
        }}
        className="save"
        autoFocus
      />
    </div>
  );

  const [formData, setFormData] = useState({
    question_En: "",
    question_Ar: "",
    answer_En: "",
    answer_Ar: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    const response = await axiosConfig
      .post("https://api.wabell.net/api/v1/faqs", formData, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        setError(error.response.data.title)
      });
  };

  const deleteUser = async (uuid) => {
    // wait for 1 second before deleting
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const response = await axiosConfig
      .delete("https://api.wabell.net/api/v1/faqs/" + uuid, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "multipart/form-data",
          "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const editUser = async () => {
    const response = await axiosConfig
      .put("https://api.wabell.net/api/v1/faqs/" + formData?.uuid, formData, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = localStorage.getItem('permissions');
    if (items) {
      setItems(items);
    }
  }, []);

  console.log(items);

  const Create = "p_f0002"; 
  const Update = "p_f0003"; 
  const Delete = "p_f0004"; 

  // Check if targetValue is included in items
  const includesCreate = items.includes(Create);
  const includesUpdate = items.includes(Update);
  const includesDelete = items.includes(Delete);

  return (
    <>
          <div className="table mt-5">
            <div>
                {/* <h1>FAQs</h1> */}
                {includesCreate ? <a className="add" onClick={() => setVisible(true)}>
                    <i className="fa fa-plus mt-4"></i>
                </a> : ""}
            </div>
            <div class="accordion" id="accordionExample">
              {data.map((x, index) => (
                <div class="accordion-item" key={index}>
                    <h2 class="accordion-header" id={x.uuid}>
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#${x.question_En}`} aria-expanded="true" aria-controls={x.question_En}>
                          {x.question_En}
                        <div className="action-table">
                          {includesUpdate ? 
                            <a
                              className="edit"
                              onClick={(e) => {
                                setVisible2(true);
                                setFormData(x);
                              }}
                            >
                              <i className="fa fa-pen"></i>
                            </a> : "" }
                            {includesDelete ? 
                            <a
                              className="delete"
                              onClick={(e) => {
                                deleteUser(x.uuid);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </a> : "" }
                        </div>
                      </button>
                    </h2>
                    <div id={x.question_En} class="accordion-collapse collapse show" aria-labelledby={x.uuid} data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      {x.answer_En}
                    </div>
                    </div>
                </div>
              ))}
            </div>
            <Dialog
              visible={visible}
              modal
              header={headerElement}
              footer={footerContent}
              style={{ width: "50rem" }}
              onHide={() => {
                if (!visible) return;
                setVisible(false);
              }}
            >
              <Form onSubmit={handleSubmit}>
                <div className="row">
                <h1 className="error-text">{error}</h1>
                  <div className="col-md-6">
                    <Form.Group className="form-group">
                      <Form.Label>Question En</Form.Label>
                      <Form.Control
                        type="text"
                        name="question_En"
                        placeholder=""
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="form-group">
                      <Form.Label>Question Ar</Form.Label>
                      <Form.Control
                        type="text"
                        name="question_Ar"
                        placeholder=""
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="form-group">
                      <Form.Label>Answer En</Form.Label>
                      <Form.Control
                        type="text"
                        name="answer_En"
                        placeholder=""
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="form-group">
                      <Form.Label>Answer Ar</Form.Label>
                      <Form.Control
                        type="text"
                        name="answer_Ar"
                        placeholder=""
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Form>
            </Dialog>
            <Dialog
              visible={visible2}
              modal
              header={headerElement2}
              footer={footerContent2}
              style={{ width: "50rem" }}
              onHide={() => {
                if (!visible2) return;
                setVisible2(false);
              }}
            >
              <Form onSubmit={editUser}>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group className="form-group">
                      <Form.Label>Name En</Form.Label>
                      <Form.Control
                        type="text"
                        name="question_En"
                        placeholder=""
                        value={formData.question_En}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="form-group">
                      <Form.Label>Name Ar</Form.Label>
                      <Form.Control
                        type="text"
                        name="question_Ar"
                        placeholder=""
                        value={formData.question_Ar}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="form-group">
                      <Form.Label>Answer En</Form.Label>
                      <Form.Control
                        type="text"
                        name="answer_En"
                        value={formData.answer_En}
                        placeholder=""
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="form-group">
                      <Form.Label>Answer Ar</Form.Label>
                      <Form.Control
                        type="text"
                        name="answer_Ar"
                        value={formData.answer_Ar}
                        placeholder=""
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Form>
            </Dialog>
          </div>
    </>
  );
}

export default FAQs;
