import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import React, { useState, useEffect } from "react";
import "../Assets/Css/Table.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { axiosConfig } from "../axiosAPI";
import { Alert, Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";

function Subscription() {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const [selectedCell, setSelectedCell] = useState(null);
  const [metaKey, setMetaKey] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);

  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        "https://api.wabell.net/api/v1/packages/subscriptions",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      );
      setCustomers(response.data.data.result);
      console.log(response);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <i className="fa fa-search"></i>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </IconField>
      </div>
    );
  };
  const header = renderHeader();
  const onRowEditComplete = (e) => {
    let _customers = [...customers];
    let { newData, index } = e;

    _customers[index] = newData;

    setCustomers(_customers);
  };
  useEffect(() => {
    getData().then((data) => {
      setLoading(false);
    });
  }, []);


  const dateFrom = (rowData) => {
    const dateString = rowData.dateFrom;
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0];
    return (
      <>
        <div className="action-table">
          <span>{formattedDate}</span>
        </div>
      </>
    );
  };

  const dateTo = (rowData) => {
    const dateString = rowData.dateTo;
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0];
    return (
      <>
        <div className="action-table">
          <span>{formattedDate}</span>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <div className="table">
              {/* <a className="add" onClick={() => setVisible(true)}>
                <i className="fa fa-plus"></i>
              </a> */}
              <DataTable
                value={customers}
                editMode="row"
                paginator
                showGridlines
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "700px" }}
                loading={loading}
                //   dataKey="id"
                filters={filters}
                globalFilterFields={[
                  "masterFullName",
                  "duration",
                  "monthlyPrice",
                  "subscriptionDayCount",
                  "totalPrice",
                  "status",
                  "dateFrom",
                  "dateTo",
                ]}
                header={header}
                emptyMessage="No customers found."
                onRowEditComplete={onRowEditComplete}
                dataKey="uuid"
                cellSelection
                selectionMode="single"
                onSelectionChange={(e) => {
                  setSelectedCell(e.value);
                  setSelectedItem(e.value.value);
                }}
              >
                <Column
                  field="masterFullName"
                  header="Name"
                  style={{ width: "12.5%" }}
                ></Column>
                <Column
                  field="duration"
                  header="duration"
                  style={{ width: "12.5%" }}
                ></Column>
                <Column
                  field="monthlyPrice"
                  header="monthly Price"
                  style={{ width: "12.5%" }}
                ></Column>
                <Column
                  field="subscriptionDayCount"
                  header="Subscription Day Count"
                  style={{ width: "12.5%" }}
                ></Column>
                <Column
                  field="totalPrice"
                  header="Total Price"
                  style={{ width: "12.5%" }}
                ></Column>
                <Column
                  field="status"
                  header="Status"
                  style={{ width: "12.5%" }}
                ></Column>
                <Column
                  field="uuid"
                  body={dateFrom}
                  header="Date From"
                  style={{ width: "12.5%" }}
                ></Column>
                <Column
                  field="uuid"
                  body={dateTo}
                  header="Date To"
                  style={{ width: "12.5%" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Subscription;
