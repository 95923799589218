import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import React, { useState, useEffect } from "react";
import "../Assets/Css/Table.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { axiosConfig } from "../axiosAPI";
import { Alert, Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import FAQs from "./FAQs";
import Contact from "./Contact";

function SettingPage() {
    const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
          <Tabs>
            <TabList>
              <div className="row">
                  <Tab className="col-6">
                    <div className="free text-center">
                      <h2>FAQs</h2>
                    </div>
                  </Tab>
                  <Tab className="col-6">
                    <div className="free text-center">
                      <h2>Content</h2>
                    </div>
                  </Tab>
              </div>
            </TabList>
            
            <TabPanel>
                <FAQs />
            </TabPanel>
            <TabPanel>
                <Contact />
            </TabPanel>
          </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export default SettingPage;
