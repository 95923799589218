import React, { useState, useEffect } from "react";
import "../Assets/Css/Table.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import Swal from "sweetalert2";
import { axiosConfig } from "../axiosAPI";
import { useAuth } from "../AuthProvider";
import { useNavigate } from "react-router-dom";

function FreePackage() {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [formData, setFormData] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        "https://api.wabell.net/api/v1/users/masters/free",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      );
      setCustomers(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <i className="fa fa-search"></i>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </IconField>
      </div>
    );
  };
  const header = renderHeader();
  const onRowEditComplete = (e) => {
    let _customers = [...customers];
    let { newData, index } = e;

    _customers[index] = newData;

    setCustomers(_customers);
  };
  useEffect(() => {
    getData().then((data) => {
      setLoading(false);
    });
  }, []);

  const statusBodyTemplate = (rowData) => {
    return rowData.applicationStatus == "Pending" ? (
      <>
        <div className="action-table">
          <a
            className="approveIcon"
          >
            <i className="fa fa-check"></i>
          </a>
          <a
            className="delete"
          >
            <i className="fa fa-close"></i>
          </a>
        </div>
      </>
    ) : rowData.applicationStatus == "Approved" ? (
      <>
        <p>Approved</p>
      </>
    ) : (
      <>
        <p>Rejected</p>
      </>
    );
  };

  const representativesItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={option.fullName}
          src={`https://api.wabell.net${option.profilePicturePath}`}
          className="ads-img"
        />
      </div>
    );
  };

  return (
    <>
        <div className="table-card">
            <div className="table">
                <DataTable
                value={customers}
                editMode="row"
                paginator
                showGridlines
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "700px" }}
                loading={loading}
                //   dataKey="id"
                filters={filters}
                globalFilterFields={[
                    "fullName",
                    "email",
                    "mobileNumber",
                    "profilePicturePath",
                    "hasActiveSubscription",
                    "cityDisplayName",
                    "availability",
                    "applicationStatusLocalized",
                    "registrationDayCount"
                ]}
                header={header}
                emptyMessage="No customers found."
                onRowEditComplete={onRowEditComplete}
                >
                <Column
                    field="profilePicturePath"
                    header="Profile Picture"
                    body={representativesItemTemplate}
                    style={{ width: "11.11111%" }}
                ></Column>
                <Column
                    field="fullName"
                    header="Name"
                    style={{ width: "11.11111%" }}
                ></Column>
                <Column
                    field="mobileNumber"
                    header="Phone Number"
                    style={{ width: "11.11111%" }}
                ></Column>
                <Column
                    field="email"
                    header="email"
                    style={{ width: "11.11111%" }}
                ></Column>
                <Column
                    field="registrationDayCount"
                    header="Registration Day"
                    style={{ width: "11.11111%" }}
                ></Column>
                <Column
                    field="hasActiveSubscription"
                    header="Active Subscription"
                    style={{ width: "11.11111%" }}
                ></Column>
                <Column
                    field="cityDisplayName"
                    header="City"
                    style={{ width: "11.11111%" }}
                ></Column>
                <Column
                    field="availability"
                    header="availability"
                    style={{ width: "11.11111%" }}
                ></Column>
                <Column
                    field="applicationStatusLocalized"
                    header="application Status"
                    style={{ width: "11.11111%" }}
                    body={statusBodyTemplate}
                ></Column>
                </DataTable>
            </div>
        </div>
    </>
  );
}

export default FreePackage;
