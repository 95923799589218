import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import React, { useState, useEffect } from "react";
import "../Assets/Css/Table.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { axiosConfig } from "../axiosAPI";
import { Alert, Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { Editor } from "primereact/editor";

function Contact() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const auth = useAuth();
    
    const [about, setAbout] = useState('');
    const [terms, setTerms] = useState('');
    const [privacy, setPrivacy] = useState('');
    
  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        "https://api.wabell.net/api/v2/settings/site-contents",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      );
      setFormData(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  
  
  const [formData, setFormData] = useState({
    about_En: "",
    about_Ar: "",
    privacyPolicy_En: "",
    privacyPolicy_Ar: "",
    termsAndConditions_En: "",
    termsAndConditions_Ar: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditorChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const editUser = async () => {
    const response = await axiosConfig
      .put("https://api.wabell.net/api/v2/settings/site-contents", formData, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
        },
      })
      .then((res) => {
        getData();
        Swal.fire({
            icon: "success",
            title: "Update Successfully",
            text: "",
            timer: 1500
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = localStorage.getItem('permissions');
    if (items) {
      setItems(items);
    }
  }, []);

  console.log(items);

  const Update = "p_s0002"; 
  
  const includesUpdate = items.includes(Update);


  useEffect(() => {
    getData().then((data) => {
      setLoading(false);
    });
  }, []);

  return (
    <>
            <div className="table mt-5">
                <div className="text-editor">
                  <h2>About Us En</h2>
                  <Editor
                    value={formData.about_En}
                    name="about_En"
                    onTextChange={(e) => handleEditorChange("about_En", e?.htmlValue || e)}
                    style={{ height: "320px" }}
                  />
                </div>
                <div className="text-editor">
                  <h2>About Us Ar</h2>
                  <Editor
                    value={formData.about_Ar}
                    name="about_Ar"
                    onTextChange={(e) => handleEditorChange("about_Ar", e?.htmlValue || e)}
                    style={{ height: "320px" }}
                  />
                </div>
                <div className="text-editor">
                  <h2>Terms and Conditions En</h2>
                  <Editor
                    value={formData.termsAndConditions_En}
                    name="termsAndConditions_En"
                    onTextChange={(e) =>
                      handleEditorChange("termsAndConditions_En", e?.htmlValue || e)
                    }
                    style={{ height: "320px" }}
                  />
                </div>
                <div className="text-editor">
                  <h2>Terms and Conditions Ar</h2>
                  <Editor
                    value={formData.termsAndConditions_Ar}
                    name="termsAndConditions_Ar"
                    onTextChange={(e) =>
                      handleEditorChange("termsAndConditions_Ar", e?.htmlValue || e)
                    }
                    style={{ height: "320px" }}
                  />
                </div>
                <div className="text-editor">
                  <h2>Privacy Policy En</h2>
                  <Editor
                    value={formData.privacyPolicy_En}
                    name="privacyPolicy_En"
                    onTextChange={(e) =>
                      handleEditorChange("privacyPolicy_En", e?.htmlValue || e)
                    }
                    style={{ height: "320px" }}
                  />
                </div>
                <div className="text-editor">
                  <h2>Privacy Policy Ar</h2>
                  <Editor
                    value={formData.privacyPolicy_Ar}
                    name="privacyPolicy_Ar"
                    onTextChange={(e) =>
                      handleEditorChange("privacyPolicy_Ar", e?.htmlValue || e)
                    }
                    style={{ height: "320px" }}
                  />
                </div>
                <div className="">
                {includesUpdate ? 
                  <a
                    className="save"
                    onClick={(e) => {
                      editUser()
                    }}
                  >
                    Save
                  </a> : "" }
                </div>
            </div>
    </>
  );
}

export default Contact;
