import React, { useState, useEffect, useRef } from "react";
import "../Assets/Css/Table.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { useDownloadExcel  } from 'react-export-table-to-excel';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import * as XLSX from 'xlsx';

import axios from "axios";
import Swal from "sweetalert2";
import { axiosConfig } from "../axiosAPI";
import { useAuth } from "../AuthProvider";
import { useNavigate } from "react-router-dom";

function Customers() {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [formData, setFormData] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        "https://api.wabell.net/api/v1/users/customers",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      );
      setCustomers(response.data.data.result);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <i className="fa fa-search"></i>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </IconField>
      </div>
    );
  };
  const header = renderHeader();
  const onRowEditComplete = (e) => {
    let _customers = [...customers];
    let { newData, index } = e;

    _customers[index] = newData;

    setCustomers(_customers);
  };
  useEffect(() => {
    getData().then((data) => {
      setLoading(false);
    });
  }, []);

  const representativesItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={option.fullName}
          src={`https://api.wabell.net${option.profilePicturePath}`}
          className="ads-img"
          onClick={(e) => {
            navigate(`/Details/${option.uuid}`);
          }}
        />
      </div>
    );
  };

  const blockUser = async (uuid) => {
    const response = await axiosConfig
      .put(`https://api.wabell.net/api/v1/users/block/${uuid}`, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const unBlockUser = async (uuid) => {
    const response = await axiosConfig
      .put(`https://api.wabell.net/api/v1/users/unblock/${uuid}`, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = localStorage.getItem('permissions');
    if (items) {
      setItems(items);
    }
  }, []);

  console.log(items);

  const Block = "p_u0003"; 

  // Check if targetValue is included in items
  const includesCreate = items.includes(Block);


  const statusAction = (rowData) => {
    return (
      <>
        <div className="action-table">
        {includesCreate ? rowData.stateLocalized == "Enabled" ? (
            <a
              className="delete"
              onClick={(e) => {
                blockUser(rowData.uuid);
              }}
            >
              <i className="fa fa-ban"></i>
            </a>
          ) : (
            <a
              className="delete"
              onClick={(e) => {
                unBlockUser(rowData.uuid);
              }}
            >
              <i className="fa fa-unlock"></i>
            </a>
          ) : ""}
          
        </div>
      </>
    );
  };

  const exportToExcel = () => {
    // Remove the 'id' column for export
    const filteredData = customers.map(({ uuid, userType, neighborhoodUuid, cityUuid, ...rest }) => rest);
    const worksheet = XLSX.utils.json_to_sheet(filteredData); 
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    XLSX.writeFile(workbook, 'Customers.xlsx');
  };


  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <div className="table">
                <a className="export" onClick={exportToExcel}>
                  <i className="fa fa-file-export"></i>
                </a>
              <DataTable
                value={customers}
                editMode="row"
                paginator
                showGridlines
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "700px" }}
                loading={loading}
                //   dataKey="id"
                filters={filters}
                globalFilterFields={[
                  "fullName",
                  "email",
                  "mobileNumber",
                  "profilePicturePath",
                ]}
                header={header}
                emptyMessage="No customers found."
                onRowEditComplete={onRowEditComplete}
              >
                {/* <Column
                  field="profilePicturePath"
                  header="Profile Picture"
                  body={representativesItemTemplate}
                  style={{ width: "20%" }}
                ></Column> */}
                <Column
                  field="fullName"
                  header="Name"
                  style={{ width: "25%" }}
                ></Column>
                <Column
                  field="mobileNumber"
                  header="Phone Number"
                  style={{ width: "25%" }}
                ></Column>
                <Column
                  field="email"
                  header="email"
                  style={{ width: "25%" }}
                ></Column>
                <Column
                  field="uuid"
                  body={statusAction}
                  header="Action"
                  style={{ width: "25%" }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Customers;
