import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";

import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
import { axiosConfig } from "../axiosAPI";

function Details() {
  const { neighborhoodUuid } = useParams();
  const [post, setPost] = useState({});
  const auth = useAuth();
  // const navigate = useNavigate();

  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        `https://api.wabell.net/api/v1/users/customers/${neighborhoodUuid}`,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      );
      setPost(response.data.data);
      console.log(response.data.data.result);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  useEffect(() => {
    getData();
  }, []);


  const blockUser = async (uuid) => {
    const response = await axiosConfig
      .put(`https://api.wabell.net/api/v1/users/block/${uuid}`, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const unBlockUser = async (uuid) => {
    const response = await axiosConfig
      .put(`https://api.wabell.net/api/v1/users/unblock/${uuid}`, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <div className="details">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-end mb-4 align-items-center">
                    <div className="d-flex justify-content-end mb-4 mt-4 align-items-center">
                      <div className="action-table">
                        {post?.stateLocalized == "Enabled" ? (
                          <a
                            className="rejected"
                            onClick={(e) => {
                              blockUser(post?.uuid);
                            }}
                          >
                            Block
                          </a>
                        ) : (
                          <a
                            className="approve"
                            onClick={(e) => {
                              unBlockUser(post?.uuid);
                            }}
                          >
                            UnBlock
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <h2>
                    Full Name: <span>{post?.fullName}</span>
                  </h2>
                </div>
                <div className="col-md-6">
                  <h2>
                    Phone Number: <span>{post?.mobileNumber}</span>
                  </h2>
                </div>
                <div className="col-md-6">
                  <h2>
                    Email: <span>{post?.email}</span>
                  </h2>
                </div>
                <div className="col-md-6">
                  <h2>
                    State: <span>{post?.stateLocalized}</span>
                  </h2>
                </div>
                <div className="col-md-6">
                  <h2>Profile Image:</h2>
                  <img
                    src={`https://api.wabell.net${post.profilePicturePath}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Details;
