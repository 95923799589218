import React, { useState, useEffect } from "react";
import "../Assets/Css/Table.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";

import { MultiSelect } from "react-multi-select-component";
import { Alert, Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { axiosConfig } from "../axiosAPI";
import { useAuth } from "../AuthProvider";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';

function Masters() {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        "https://api.wabell.net/api/v1/users/masters",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      );
      setCustomers(response.data.data.result);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <i className="fa fa-search"></i>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </IconField>
      </div>
    );
  };
  const header = renderHeader();
  const onRowEditComplete = (e) => {
    let _customers = [...customers];
    let { newData, index } = e;

    _customers[index] = newData;

    setCustomers(_customers);
  };
  useEffect(() => {
    getData().then((data) => {
      setLoading(false);
    });
  }, []);
  const [visible2, setVisible2] = useState(false);
  const headerElement2 = (
    <div className="d-flex align-items-center justify-content-between gap-2">
      <span className="font-bold white-space-nowrap">Edit Master</span>
    </div>
  );
  const footerContent2 = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible2(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => {
          editUser();
          setVisible2(false);
        }}
        className="save"
        autoFocus
      />
    </div>
  );

  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    state: [],
    mobileNumber: "",
    idFiles: [],
    certificates: [],
    education: "",
    tagline: "",
    biography: "",
    experience: "",
    pricePerHour: "",
    availableDays: [],
    availableTimes: [],
  });

  const approveUser = async (uuid) => {
    const response = await axiosConfig
      .put(
        "https://api.wabell.net/api/v1/users/masters/" + uuid + "/approve",
        {},
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      )
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
    // alert(uuid)
  };

  const rejectUser = async (uuid) => {
    const response = await axiosConfig
      .put(
        "https://api.wabell.net/api/v1/users/masters/" + uuid + "/reject",
        {},
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      )
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
    // alert(uuid);
  };

  const blockUser = async (uuid) => {
    const response = await axiosConfig
      .put(`https://api.wabell.net/api/v1/users/block/${uuid}`, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const unBlockUser = async (uuid) => {
    const response = await axiosConfig
      .put(`https://api.wabell.net/api/v1/users/unblock/${uuid}`, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const editUser = async () => {
    var data = {
      ...formData,
      availableDays: selected.map((el) => el.value),
    };

    const response = await axiosConfig
      .put(
        "https://api.wabell.net/api/v1/users/masters/" + formData?.uuid,
        data,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "multipart/form-data",
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      )
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

    const isArray = (val) => {
      const toString = {}.toString;
      return toString.call(val) === "[object Array]";
    };

    const isObject = (val) => {
      return !isArray(val) && typeof val === "object" && !!val;
    };

    const convertJsontoFormData = (
      jsonObject,
      parentKey = null,
      carryFormData = new FormData()
    ) => {
      const formData = carryFormData;

      for (let i = 0; i < Object.keys(jsonObject).length; i++) {
        let key = Object.keys(jsonObject)[i];
        if (jsonObject.hasOwnProperty(key)) {
          if (
            Object.values(jsonObject)[i] !== null &&
            Object.values(jsonObject)[i] !== undefined
          ) {
            var propName = parentKey || key;
            if (parentKey && isObject(jsonObject)) {
              propName = parentKey + "." + key;
            }

            if (parentKey && isArray(jsonObject)) {
              propName = parentKey + "[" + i + "]";
            }

            if (Object.values(jsonObject)[i] instanceof File) {
              formData.append(propName, Object.values(jsonObject)[i]);
            } else if (Object.values(jsonObject)[i] instanceof FileList) {
              for (var j = 0; j < Object.values(jsonObject)[i].length; j++) {
                formData.append(
                  propName + "[" + j + "]",
                  Object.values(jsonObject)[i].item(j)
                );
              }
            } else if (
              isArray(Object.values(jsonObject)[i]) ||
              isObject(Object.values(jsonObject)[i])
            ) {
              convertJsontoFormData(
                Object.values(jsonObject)[i],
                propName,
                formData
              );
            } else if (typeof Object.values(jsonObject)[i] === "boolean") {
              formData.append(
                propName,
                +Object.values(jsonObject)[i] ? "true" : "false"
              );
            } else {
              formData.append(propName, Object.values(jsonObject)[i]);
            }
          }
        }
      }

      return formData;
    };
  
  const availableDays = [
    { label: "Saturday", value: "Saturday" },
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
  ];
  
  const availableTimes = [
    { label: "Morning", value: "Morning" },
    { label: "Afternoon", value: "Afternoon" },
    { label: "Evening", value: "Evening" },
  ];

  const [items, setItems] = useState([]);

  const [selected, setSelected] = useState([]);
  const [selected2, setSelected2] = useState([]);
  useEffect(() => {
    const items = localStorage.getItem('permissions');
    if (items) {
      setItems(items);
    }
  }, []);

  console.log(items);

  const Block = "p_u0003"; 
  const Approve = "p_u0004"; 

  // Check if targetValue is included in items
  const includesCreate = items.includes(Block);
  const includesApprove = items.includes(Approve);

  const statusAction = (rowData) => {
    return (
      <>
        <div className="action-table">
          {includesCreate ? (
            rowData?.applicationStatus == "Pending" ? (
              <></>
            ) : rowData?.applicationStatus == "Approved" ? (
              <>
                {rowData.stateLocalized == "Enabled" ? (
                  <div className="table-action d-flex">
                    <a
                      className="delete"
                      onClick={(e) => {
                        blockUser(rowData.uuid);
                      }}
                    >
                      <i className="fa fa-ban"></i>
                    </a>
                    <a
                      className="edit"
                      onClick={(e) => {
                        setVisible2(true);
                        setFormData(rowData);
                      }}
                    >
                      <i className="fa fa-pen"></i>
                    </a>
                  </div>
                ) : (
                  <div className="table-action d-flex">
                    <a
                      className="delete"
                      onClick={(e) => {
                        unBlockUser(rowData.uuid);
                      }}
                    >
                      <i className="fa fa-unlock"></i>
                    </a>
                    <a
                      className="edit"
                      onClick={(e) => {
                        setVisible2(true);
                        setFormData(rowData);
                      }}
                    >
                      <i className="fa fa-pen"></i>
                    </a>
                  </div>
                )}
              </>
            ) : (
              <></>
            )
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <>
      {includesApprove ? 

        rowData.applicationStatus == "Pending" ? (
          <>
            <div className="action-table">
              <a
                className="approveIcon"
                onClick={(e) => {
                  approveUser(rowData.uuid);
                }}
              >
                <i className="fa fa-check"></i>
              </a>
              <a
                className="delete"
                onClick={(e) => {
                  rejectUser(rowData.uuid);
                }}
              >
                <i className="fa fa-close"></i>
              </a>
            </div>
          </>
        ) : rowData.applicationStatus == "Approved" ? (
          <>
            <p>Approved</p>
          </>
        ) : (
          <>
            <p>Rejected</p>
          </>
        ) : "" }
      </>
    )
  };

  const representativesItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={option.fullName}
          src={`https://api.wabell.net${option.profilePicturePath}`}
          className="ads-img"
          onClick={(e) => {
            navigate(`/MasterDetails/${option.uuid}`);
          }}
        />
      </div>
    );
  };

  const exportToExcel = () => {
    // Remove the 'id' column for export
    const filteredData = customers.map(({ uuid, userType, neighborhoodUuid, cityUuid, ...rest }) => rest);
    
    const worksheet = XLSX.utils.json_to_sheet(filteredData); 
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    XLSX.writeFile(workbook, 'Masters.xlsx');
  };

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <div className="table">
              <a className="export" onClick={exportToExcel}>
                <i className="fa fa-file-export"></i>
              </a>
              <DataTable
                value={customers}
                editMode="row"
                paginator
                showGridlines
                rows={10}
                id="my-table"
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "700px" }}
                loading={loading}
                //   dataKey="id"
                filters={filters}
                globalFilterFields={[
                  "fullName",
                  "email",
                  "mobileNumber",
                  "profilePicturePath",
                  "hasActiveSubscription",
                  "cityDisplayName",
                  "availability",
                  "applicationStatusLocalized",
                  "registrationDayCount",
                ]}
                header={header}
                emptyMessage="No customers found."
                onRowEditComplete={onRowEditComplete}
              >
                <Column
                  field="profilePicturePath"
                  header="Profile Picture"
                  body={representativesItemTemplate}
                  style={{ width: "11.11111%" }}
                ></Column>
                <Column
                  field="fullName"
                  header="Name"
                  style={{ width: "11.11111%" }}
                ></Column>
                <Column
                  field="mobileNumber"
                  header="Phone Number"
                  style={{ width: "11.11111%" }}
                ></Column>
                <Column
                  field="email"
                  header="email"
                  style={{ width: "11.11111%" }}
                ></Column>
                <Column
                  field="registrationDayCount"
                  header="Registration Day"
                  style={{ width: "11.11111%" }}
                ></Column>
                <Column
                  field="hasActiveSubscription"
                  header="Active Subscription"
                  style={{ width: "11.11111%" }}
                ></Column>
                <Column
                  field="cityDisplayName"
                  header="City"
                  style={{ width: "11.11111%" }}
                ></Column>
                <Column
                  field="availability"
                  header="availability"
                  style={{ width: "11.11111%" }}
                ></Column>
                <Column
                  field="applicationStatusLocalized"
                  header="application Status"
                  style={{ width: "11.11111%" }}
                  body={statusBodyTemplate}
                ></Column>
                <Column
                  field="uuid"
                  body={statusAction}
                  header="Action"
                  style={{ width: "11.11111%" }}
                ></Column>
              </DataTable>
              <Dialog
                visible={visible2}
                modal
                header={headerElement2}
                footer={footerContent2}
                style={{ width: "50rem" }}
                onHide={() => {
                  if (!visible2) return;
                  setVisible2(false);
                }}
              >
                <Form onSubmit={editUser}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="fullName"
                          placeholder=""
                          value={formData.fullName}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder=""
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Education</Form.Label>
                        <Form.Control
                          type="text"
                          name="education"
                          placeholder=""
                          value={formData.education}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Experience</Form.Label>
                        <Form.Control
                          type="text"
                          name="experience"
                          placeholder=""
                          value={formData.experience}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Tagline</Form.Label>
                        <Form.Control
                          type="text"
                          name="tagline"
                          placeholder=""
                          value={formData.tagline}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Biography</Form.Label>
                        <Form.Control
                          type="text"
                          name="biography"
                          placeholder=""
                          value={formData.biography}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Price Per Hour</Form.Label>
                        <Form.Control
                          type="text"
                          name="pricePerHour"
                          placeholder=""
                          value={formData.pricePerHour}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>State</Form.Label>
                        <select
                          className="form-control"
                          name="state"
                          value={formData.state}
                          onChange={handleChange}
                        >
                          <option value="Enabled">Enabled</option>
                          <option value="Disabled">Disabled</option>
                        </select>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Available Times</Form.Label>
                        {/* <select
                          className="form-control"
                          name="availableTimes"
                          value={formData.availableTimes}
                          onChange={handleChange}
                        >
                          <option value="Morning">Morning</option>
                          <option value="Afternoon">Afternoon</option>
                          <option value="Evening">Evening</option>
                        </select> */}
                        <MultiSelect
                          options={availableTimes}
                          value={selected2}
                          onChange={setSelected2}
                          labelledBy="Select"
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Available Days</Form.Label>
                        {/* <select
                          className="form-control"
                          name="availableDays"
                          value={formData.availableDays}
                          onChange={handleChange}
                        >
                          <option value="Saturday">Saturday</option>
                          <option value="Sunday">Sunday</option>
                          <option value="Monday">Monday</option>
                          <option value="Tuesday">Tuesday</option>
                          <option value="Wednesday">Wednesday</option>
                          <option value="Thursday">Thursday</option>
                          <option value="Friday">Friday</option>
                        </select> */}
                        <MultiSelect
                          options={availableDays}
                          value={selected}
                          onChange={setSelected}
                          labelledBy="Select"
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Id Files</Form.Label>
                        <Form.Control
                          id="idFiles"
                          className="file"
                          type="file"
                          multiple
                          name="idFiles"
                          placeholder="text"
                          onChange={handleFileChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Certificates</Form.Label>
                        <Form.Control
                          id="certificates"
                          className="file"
                          type="file"
                          name="certificates"
                          multiple
                          placeholder="text"
                          onChange={handleFileChange}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Form>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Masters;
