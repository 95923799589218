import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("accessToken") || "");
  const navigate = useNavigate();

  const loginAction = async (data) => {
    try {
      const response = await fetch(
        "https://api.wabell.net/api/v1/auth/authenticate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
          body: JSON.stringify(data),
        }
      );
      const res = await response.json();
      if (res) {
        setUser(res.data.refreshToken);
        setToken(res.data.accessToken);
        setPermissions(res.data.permissions);
        localStorage.setItem("accessToken", res.data.accessToken);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        localStorage.setItem("permissions", res.data.permissions);
        navigate("/Cities");
        return;
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
  };

  const auth = useAuth();
  const logOut = async (data) => {
    try {
      const response = await fetch(
        "https://api.wabell.net/api/v1/auth/logout",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      );
      if (response) {
        setUser(null);
        setToken("");
        localStorage.clear();
        navigate("/");
        return;
      }
      throw new Error(response.message);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AuthContext.Provider value={{ token, user, permissions, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
