import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import React, { useState, useEffect } from "react";
import "../Assets/Css/Table.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { axiosConfig } from "../axiosAPI";
import { Alert, Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";

function Admins() {

  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState('');
  const [roles, setRoles] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        "https://api.wabell.net/api/v1/admins",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      );
      setCustomers(response.data.data.result);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const getRoles = async () => {
    try {
      const response = await axiosConfig.get(
        "https://api.wabell.net/api/v1/roles",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      );
      setRoles(response.data.data.result);
      console.log(response.data.data.result);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <i className="fa fa-search"></i>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </IconField>
      </div>
    );
  };
  const header = renderHeader();
  const onRowEditComplete = (e) => {
    let _customers = [...customers];
    let { newData, index } = e;

    _customers[index] = newData;

    setCustomers(_customers);
  };
  useEffect(() => {
    getData().then((data) => {
      setLoading(false);
    });
    getRoles()
  }, []);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const headerElement2 = (
    <div className="d-flex align-items-center justify-content-between gap-2">
      <span className="font-bold white-space-nowrap">Chang Password</span>
    </div>
  );
  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Add Admin</span>
    </div>
  );
  const footerContent = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => {
          handleSubmit();
          {!error ? setVisible(true) : setVisible(false)}
        }}
        className="save"
        autoFocus
      />
    </div>
  );
  const footerContent2 = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible2(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => {
          ChangPassword();
          setVisible2(false);
        }}
        className="save"
        autoFocus
      />
    </div>
  );
  const headerElement3 = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Edit Roles</span>
    </div>
  );
  const footerContent3 = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible3(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => {
          changRoles();
          setVisible3(false);
        }}
        className="save"
        autoFocus
      />
    </div>
  );

  const [selectedRoles, setSelectedRoles] = useState([]);

  const handleCheckboxChange = (identifier) => {
    console.log(identifier);
    setSelectedRoles((prev) =>
      prev.includes(identifier)
        ? prev.filter((role) => role !== identifier)
        : [...prev, identifier]
    );
  };


  const handleCheckboxChange2 = (identifier) => {
    setSelectedRoles((prev) =>
        prev.includes(identifier)
            ? prev.filter((role) => role !== identifier) // Remove role if already selected
            : [...prev, identifier]  // Add role if not selected
    );
  };

  const [newPassword, setNewPassword] = useState({
    fullName: "",
    confirmNewPassword: "",
  });

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    Password: "",
    confirmPassword: "",
    state: [],
    profilePictureFile: [],
    // Roles: selectedRoles
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      Roles: selectedRoles,
    }));
  }, [selectedRoles]);

  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setNewPassword({ ...newPassword, [name]: value });
  };
  
  const gotToNewPage = (rowData) => {
    return (
      <>
        {includesUpdate ? <a
        className="state-admin"
        onClick={(e) => {
          changState(rowData)
        }}
      >
        {rowData.state}
      </a> : "" }
      </>
    );
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    const updatedFormData = {
        ...formData,
        Roles: selectedRoles, // Add selected roles to formData
    };

    const data = convertJsontoFormData(updatedFormData);

    try {
        const response = await axiosConfig.post(
            "https://api.wabell.net/api/v1/admins",
            data,
            {
                headers: {
                    Authorization: "Bearer " + auth.token,
                    "Content-Type": "multipart/form-data",
                    "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
                },
            }
        );
        getData(); // Refresh data after submission
        setVisible(false); // Close dialog
    } catch (error) {
      setError(error.response.data.title)
    }
};

  const changState = async (rowData) => {
    const response = await axiosConfig
      .put(`https://api.wabell.net/api/v1/admins/${rowData.uuid}/state?state=${rowData.state == "Disabled" ? "Enabled" : "Disabled" }`, {
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const deleteUser = async (uuid) => {
    // wait for 1 second before deleting
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const response = await axiosConfig
      .delete("https://api.wabell.net/api/v1/admins/" + uuid, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "multipart/form-data",
          "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const ChangPassword = async () => {
    const response = await axiosConfig
      .put("https://api.wabell.net/api/v1/admins/" + newPassword?.uuid + "/password", newPassword, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = localStorage.getItem('permissions');
    if (items) {
      setItems(items);
    }
  }, []);

  console.log(items);

  const Create = "p_r0002"; 
  const Update = "p_r0003"; 
  const Delete = "p_r0004"; 

  // Check if targetValue is included in items
  const includesCreate = items.includes(Create);
  const includesUpdate = items.includes(Update);
  const includesDelete = items.includes(Delete);

  const statusAction = (rowData) => {
    return (
      <>
      <div className="action-table">
        {includesUpdate ? <a
          className="edit"
          onClick={(e) => {
            setVisible2(true);
            setNewPassword(rowData);
          }}
        >
          <i className="fa fa-key"></i>
        </a> : "" }
        {includesUpdate ? <a
          className="eye"
          onClick={(e) => {
            setVisible3(true);
            setFormData(rowData);
          }}
        >
          <i className="fa fa-list"></i>
        </a> : "" }
        {includesDelete ? <a
          className="delete"
          onClick={(e) => {
            deleteUser(rowData.uuid);
          }}
        >
          <i className="fa fa-trash"></i>
        </a> : "" }
      </div>
      </>
    );
  };

  const changRoles = async () => {
    const response = await axiosConfig
      .put("https://api.wabell.net/api/v1/admins/" + formData?.uuid + "/roles", selectedRoles, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const isArray = (val) => {
    const toString = ({}).toString;
    return toString.call(val) === '[object Array]';
}

  const isObject = (val) => {
      return !isArray(val) && typeof val === 'object' && !!val;
  }

  const convertJsontoFormData = (jsonObject, parentKey = null, carryFormData = new FormData()) => {
    const formData = carryFormData;

    for (let i = 0; i < Object.keys(jsonObject).length; i++) {
        let key = Object.keys(jsonObject)[i];
        if (jsonObject.hasOwnProperty(key)) {
            if (Object.values(jsonObject)[i] !== null && Object.values(jsonObject)[i] !== undefined) {
                var propName = parentKey || key;
                if (parentKey && isObject(jsonObject)) {
                    propName = parentKey + '.' + key;
                }

                if (parentKey && isArray(jsonObject)) {
                    propName = parentKey + '[' + i + ']';
                }

                if (Object.values(jsonObject)[i] instanceof File) {
                    formData.append(propName, Object.values(jsonObject)[i]);
                } else if (Object.values(jsonObject)[i] instanceof FileList) {
                    for (var j = 0; j < Object.values(jsonObject)[i].length; j++) {
                        formData.append(propName + '[' + j + ']', Object.values(jsonObject)[i].item(j));
                    }
                } else if (isArray(Object.values(jsonObject)[i]) || isObject(Object.values(jsonObject)[i])) {
                    convertJsontoFormData(Object.values(jsonObject)[i], propName, formData);
                } else if (typeof Object.values(jsonObject)[i] === 'boolean') {
                    formData.append(propName, +Object.values(jsonObject)[i] ? 'true' : 'false');
                } else {
                    formData.append(propName, Object.values(jsonObject)[i]);
                }
            }
        }
    }

    return formData;
  }


  return (
    <>
    <div className="main">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="dev-table">
        <Navbar />
        <div className="table-card">
          <div className="table">
              {includesCreate ? <a className="add" onClick={() => setVisible(true)}>
                <i className="fa fa-plus"></i>
              </a> : "" }
              <DataTable
                value={customers}
                editMode="row"
                paginator
                showGridlines
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "700px" }}
                loading={loading}
                //   dataKey="id"
                filters={filters}
                globalFilterFields={[
                  "fullName",
                  "email",
                  "mobileNumber",
                  "state",
                ]}
                header={header}
                emptyMessage="No customers found."
                onRowEditComplete={onRowEditComplete}
              >
                <Column
                  field="fullName"
                  header="Name"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="mobileNumber"
                  header="Phone Number"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="email"
                  header="email"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="uuid"
                  body={gotToNewPage}
                  header="State"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="uuid"
                  body={statusAction}
                  header="Action"
                  style={{ width: "20%" }}
                ></Column>
              </DataTable>
              <Dialog
                visible={visible}
                modal
                header={headerElement}
                footer={footerContent}
                style={{ width: "50rem" }}
                onHide={() => {
                  if (!visible) return;
                  setVisible(false);
                }}
              >
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                  <h1 className="error-text">{error}</h1>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="fullName"
                          placeholder=""
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder=""
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control
                          type="number"
                          name="mobileNumber"
                          placeholder=""
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="Password"
                          placeholder=""
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Confirm Password </Form.Label>
                        <Form.Control
                          type="password"
                          name="confirmPassword"
                          placeholder=""
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>State</Form.Label>
                        <select className="form-control" name="state" onChange={handleChange}>
                          <option>Select</option>
                          <option value="Enabled">Enabled</option>
                          <option value="Disabled">Disabled</option>
                        </select>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Profile Picture File</Form.Label>
                        <Form.Control
                          id="profilePictureFile"
                          className="file"
                          type="file"
                          name="profilePictureFile"
                          placeholder="text"
                          onChange={handleFileChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
    <div className="row">
        <label>Roles</label>
        {roles.map((x, index) => (
            <div className="col-md-3" key={index}>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value={x.identifier}
                        id={x.identifier}
                        name="identifier"
                        checked={selectedRoles.includes(x.identifier)}
                        onChange={() => handleCheckboxChange2(x.identifier)} // Handle role change
                    />
                    <label className="form-check-label" htmlFor={x.identifier}>
                        {x.displayName_En}
                    </label>
                </div>
            </div>
        ))}
    </div>
</div>
                  </div>
                </Form>
              </Dialog>
              <Dialog
                visible={visible2}
                modal
                header={headerElement2}
                footer={footerContent2}
                style={{ width: "50rem" }}
                onHide={() => {
                  if (!visible2) return;
                  setVisible2(false);
                }}
              >
                <Form >
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="newPassword"
                          placeholder=""
                          value={newPassword.newPassword}
                          onChange={handleChange2}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="confirmNewPassword"
                          placeholder=""
                          value={newPassword.confirmNewPassword}
                          onChange={handleChange2}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Form>
              </Dialog>
              <Dialog
                visible={visible3}
                modal
                header={headerElement3}
                footer={footerContent3}
                style={{ width: "50rem" }}
                onHide={() => {
                  if (!visible3) return;
                  setVisible3(false);
                }}
              >
                <Form onSubmit={changRoles}>
                  <div className="row">
                    {roles.map((x, index) => (
                      <div className="col-md-3" key={index}>
                          <div className="form-check">
                            <input class="form-check-input" type="checkbox" value={x.identifier} id={x.identifier} name="identifier" onChange={() => handleCheckboxChange(x.identifier)} />
                            <label class="form-check-label" for={x.identifier}>
                              {x.displayName_En}
                            </label>
                          </div>
                      </div>
                    ))}
                  </div>
                </Form>
              </Dialog>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Admins;
