import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import { useAuth } from "../AuthProvider";

function Dashboard() {
  const auth = useAuth();


  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="charts-card">
            <div className="row">
              <div className="col-md-4">
                <div className="card-details">
                  <h2>
                    Number of requests <span>12</span>
                  </h2>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-details">
                  <h2>
                    Number of Users <span>200</span>
                  </h2>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-details">
                  <h2>
                    Total Payment <span>13502</span>
                  </h2>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-details">
                  {/* <Option /> */}
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card-details">
                  {/* <Month /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
