import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import React, { useState, useEffect } from "react";
import "../Assets/Css/Table.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { axiosConfig } from "../axiosAPI";
import { Alert, Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";

function Roles() {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const [selectedCell, setSelectedCell] = useState(null);
  const [metaKey, setMetaKey] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);

  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        "https://api.wabell.net/api/v1/roles",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      );
      setCustomers(response.data.data.result);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  const getPermissions = async () => {
    try {
      const response = await axiosConfig.get(
        "https://api.wabell.net/api/v1/roles/groups",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      );
      setPermissions(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <i className="fa fa-search"></i>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </IconField>
      </div>
    );
  };
  const header = renderHeader();
  const onRowEditComplete = (e) => {
    let _customers = [...customers];
    let { newData, index } = e;

    _customers[index] = newData;

    setCustomers(_customers);
  };
  useEffect(() => {
    getData().then((data) => {
      setLoading(false);
    });
    getPermissions()
  }, []);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const headerElement2 = (
    <div className="d-flex align-items-center justify-content-between gap-2">
      <span className="font-bold white-space-nowrap">Edit Roles</span>
    </div>
  );
  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Add Roles</span>
    </div>
  );
  const footerContent = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => {
          handleSubmit();
          {!error ? setVisible(true) : setVisible(false)}
        }}
        className="save"
        autoFocus
      />
    </div>
  );
  const footerContent2 = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible2(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => {
          editUser();
          setVisible2(false);
        }}
        className="save"
        autoFocus
      />
    </div>
  );
  

  const [selectedRoles, setSelectedRoles] = useState([]);
  const handleCheckboxChange2 = (identifier) => {
    setSelectedRoles((prev) => {
      const updatedRoles = prev.includes(identifier)
        ? prev.filter((role) => role !== identifier)
        : [...prev, identifier];
  
      // Format each role with "Roles:" prefix and join them with newline
      const formattedRoles = updatedRoles.map((role) => `Roles: ${role}`).join('\n');
      console.log(formattedRoles);
  
      return updatedRoles;
    });
  };

  const [formData, setFormData] = useState({
    displayName_Ar: "",
    description_En: "",
    description_Ar: "",
    status: [],
    identifier: "",
    permissionCount: "",
    displayName_En: "",
    permissions: []
  });

  

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      permissions: selectedRoles,
    }));
  }, [selectedRoles]);

  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  


  // console.log(selectedItem);
  const gotToNewPage = (rowData) => {
    return (
      <a
        className="edit"
        onClick={(e) => {
          navigate(`/CitiesDetails/${rowData.uuid}`);
        }}
      >
        details
      </a>
    );
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    const response = await axiosConfig
      .post("https://api.wabell.net/api/v1/roles", formData, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        setError(error.response.data.title)
      });
  };

  const deleteUser = async (uuid) => {
    // wait for 1 second before deleting
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const response = await axiosConfig
      .delete("https://api.wabell.net/api/v1/roles/" + uuid, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "multipart/form-data",
          "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const editUser = async () => {
    const response = await axiosConfig
      .put("https://api.wabell.net/api/v1/roles/" + formData?.uuid, formData, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  
  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = localStorage.getItem('permissions');
    if (items) {
      setItems(items);
    }
  }, []);

  console.log(items);

  const Create = "p_r0002"; 
  const Update = "p_r0003"; 
  const Delete = "p_r0004"; 

  // Check if targetValue is included in items
  const includesCreate = items.includes(Create);
  const includesUpdate = items.includes(Update);
  const includesDelete = items.includes(Delete);

  const statusAction = (rowData) => {
    return (
      <>
      {rowData.uuid == "00000000-0000-0000-0000-000000000000" ? "" : 
        <div className="action-table">
          {includesUpdate ? <a
            className="edit"
            onClick={(e) => {
              setVisible2(true);
              setFormData(rowData);
            }}
          >
            <i className="fa fa-pen"></i>
          </a> : "" }
          {includesDelete ? <a
            className="delete"
            onClick={(e) => {
              deleteUser(rowData.uuid);
            }}
          >
            <i className="fa fa-trash"></i>
          </a> : "" }
        </div>}
      </>
    );
  };
  

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <div className="table">
            {includesCreate ?
              <a className="add" onClick={() => setVisible(true)}>
                <i className="fa fa-plus"></i>
              </a> : "" }
              <DataTable
                value={customers}
                editMode="row"
                paginator
                showGridlines
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "700px" }}
                loading={loading}
                //   dataKey="id"
                filters={filters}
                globalFilterFields={[
                  "displayName_Ar",
                  "description_En",
                  "description_Ar",
                  "status",
                  "identifier",
                  "permissionCount",
                  "displayName_En",
                ]}
                header={header}
                emptyMessage="No customers found."
                onRowEditComplete={onRowEditComplete}
                dataKey="uuid"
                cellSelection
                selectionMode="single"
                onSelectionChange={(e) => {
                  setSelectedCell(e.value);
                  setSelectedItem(e.value.value);
                }}
              >
                <Column
                  field="displayName_Ar"
                  header="Name Ar"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="displayName_En"
                  header="Name En"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="description_En"
                  header="Description En"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="description_Ar"
                  header="Description Ar"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="status"
                  header="State"
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="uuid"
                  header="Action"
                  style={{ width: "20%" }}
                  body={statusAction}
                ></Column>
                {/*  new column for actions (edit & delete) */}
              </DataTable>
              <Dialog
                visible={visible}
                modal
                header={headerElement}
                footer={footerContent}
                style={{ width: "50rem" }}
                onHide={() => {
                  if (!visible) return;
                  setVisible(false);
                }}
              >
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                  <h1 className="error-text">{error}</h1>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Name En</Form.Label>
                        <Form.Control
                          type="text"
                          name="displayName_En"
                          placeholder=""
                          value={formData.displayName_En}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Name Ar</Form.Label>
                        <Form.Control
                          type="text"
                          name="displayName_Ar"
                          placeholder=""
                          value={formData.displayName_Ar}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Description Ar</Form.Label>
                        <Form.Control
                          type="text"
                          name="description_Ar"
                          placeholder=""
                          value={formData.description_Ar}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Description En</Form.Label>
                        <Form.Control
                          type="text"
                          name="description_En"
                          placeholder=""
                          value={formData.description_En}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Status</Form.Label>
                        <select name="status" value={formData.status} onChange={handleChange} className="form-control">
                            <option>Select</option>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                        </select>
                      </Form.Group>
                    </div>
                    {permissions.map((x, index) => (
                        <div className="col-md-12" key={index}>
                            <div className="permissions">
                                <h2>{x.displayName}</h2>
                                <div className="row">
                                    {x.permissions.map((y, index) => (
                                        <div className="col-md-3" key={index}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={y.identifier} id={y.identifier} name="identifier" onChange={() => handleCheckboxChange2(y.identifier)} />
                                                <label className="form-check-label" htmlFor={y.identifier}>
                                                {y.displayName}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                  </div>
                </Form>
              </Dialog>
              <Dialog
                visible={visible2}
                modal
                header={headerElement2}
                footer={footerContent2}
                style={{ width: "50rem" }}
                onHide={() => {
                  if (!visible2) return;
                  setVisible2(false);
                }}
              >
                <Form onSubmit={editUser}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Name En</Form.Label>
                        <Form.Control
                          type="text"
                          name="displayName_En"
                          placeholder=""
                          value={formData.displayName_En}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Name Ar</Form.Label>
                        <Form.Control
                          type="text"
                          name="displayName_Ar"
                          placeholder=""
                          value={formData.displayName_Ar}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Description Ar</Form.Label>
                        <Form.Control
                          type="text"
                          name="description_Ar"
                          placeholder=""
                          value={formData.description_Ar}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Description En</Form.Label>
                        <Form.Control
                          type="text"
                          name="description_En"
                          placeholder=""
                          value={formData.description_En}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>Status</Form.Label>
                        <select name="status" value={formData.status} onChange={handleChange} className="form-control">
                            <option>Select</option>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                        </select>
                      </Form.Group>
                    </div>
                    {permissions.map((x, index) => (
                        <div className="col-md-12" key={index}>
                            <div className="permissions">
                                <h2>{x.displayName}</h2>
                                <div className="row">
                                    {x.permissions.map((y, index) => (
                                        <div className="col-md-3" key={index}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={formData.permissions} id={y.identifier} name="identifier" onChange={() => handleCheckboxChange2(y.identifier)} />
                                                <label className="form-check-label" htmlFor={y.identifier}>
                                                {y.displayName}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                  </div>
                </Form>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Roles;
