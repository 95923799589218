import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./Assets/Css/Web.css";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import AuthProvider from "./AuthProvider";
import PrivateRoute from "./route";
import Cities from "./Pages/Cities";
import Package from "./Pages/Package";
import Specialties from "./Pages/Specialties";
import Customers from "./Pages/Customers";
import Masters from "./Pages/Masters";
import Details from "./Pages/Details";
import MasterDetails from "./Pages/MasterDetails";
import CitiesDetails from "./Pages/CitiesDetails"; 
import SpecialtiesRequest from "./Pages/SpecialtiesRequest";
import Subscription from "./Pages/Subscription";
import Admins from "./Pages/Setting";
import Roles from "./Pages/Roles";
import FAQs from "./Pages/FAQs";
import Contact from "./Pages/Contact";
import SettingPage from "./Pages/SettingPage";

function App() {
  

  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route element={<PrivateRoute />}>
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/Cities" element={<Cities />} />
              <Route path="/Package" element={<Package />} />
              <Route exact path="/Specialties" element={<Specialties />} />
              <Route exact path="/Customers" element={<Customers />} />
              <Route exact path="/Masters" element={<Masters />} />
              <Route exact path="/Subscription" element={<Subscription />} />
              <Route exact path="/Admins" element={<Admins />} />
              <Route exact path="/Roles" element={<Roles />} />
              <Route exact path="/FAQs" element={<FAQs />} />
              <Route exact path="/Contact" element={<Contact />} />
              <Route exact path="/SettingPage" element={<SettingPage />} />
              <Route
                exact
                path="/SpecialtiesRequest"
                element={<SpecialtiesRequest />}
              />
              <Route
                exact
                path="/Details/:neighborhoodUuid"
                element={<Details />}
              />
              <Route
                exact
                path="/MasterDetails/:neighborhoodUuid"
                element={<MasterDetails />}
              />
              <Route
                exact
                path="/CitiesDetails/:neighborhoodUuid"
                element={<CitiesDetails />}
              />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}


export default App;
