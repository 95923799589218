import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";

import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
import { axiosConfig } from "../axiosAPI";
import { Link } from "react-router-dom";

function MasterDetails() {
  const { neighborhoodUuid } = useParams();
  const [post, setPost] = useState({});
  const auth = useAuth();
  console.log(neighborhoodUuid);
  // const navigate = useNavigate();

  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        `https://api.wabell.net/api/v1/users/masters/${neighborhoodUuid}`,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      );
      setPost(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const approveUser = async (neighborhoodUuid) => {
    const response = await axiosConfig
      .put(
        "https://api.wabell.net/api/v1/users/masters/" +
        neighborhoodUuid +
        "/approve",
        {},
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      )
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
    // alert(uuid)
  };

  const rejectUser = async (neighborhoodUuid) => {
    const response = await axiosConfig
      .put(
        "https://api.wabell.net/api/v1/users/masters/" +
        neighborhoodUuid +
        "/reject",
        {},
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      )
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
    // alert(uuid);
  };

  const deleteFiles = async (uuid, x) => {
    // wait for 1 second before deleting
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const response = await axiosConfig
      .delete(
        "https://api.wabell.net/api/v1/users/masters/" + uuid + "/idfiles", {
          data: { filePath: x },
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      )
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const deleteCertificates = async (uuid, x) => {
    // wait for 1 second before deleting
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const response = await axiosConfig
      .delete(
        "https://api.wabell.net/api/v1/users/masters/" + uuid + "/certificates",
        {
          data: { filePath: x },
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        }
      )
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const blockUser = async (uuid) => {
    const response = await axiosConfig
      .put(`https://api.wabell.net/api/v1/users/block/${uuid}`, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const unBlockUser = async (uuid) => {
    const response = await axiosConfig
      .put(`https://api.wabell.net/api/v1/users/unblock/${uuid}`, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };
  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = localStorage.getItem('permissions');
    if (items) {
      setItems(items);
    }
  }, []);

  console.log(items);

  const Block = "p_u0003"; 
  const Approve = "p_u0004"; 

  // Check if targetValue is included in items
  const includesCreate = items.includes(Block);
  const includesApprove = items.includes(Approve);


  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <div className="details">
              <Link to="/Masters" className="details-back">
                <i className="fa fa-arrow-left"></i>
              </Link>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-end mb-4 mt-4 align-items-center">
                    {includesApprove ? (
                      post?.applicationStatus == "Pending" ? (
                        <>
                          <div className="action-table">
                            <a
                              className="approve"
                              onClick={(e) => {
                                approveUser(post?.uuid);
                              }}
                            >
                              Approve
                            </a>
                            <a
                              className="rejected"
                              onClick={(e) => {
                                rejectUser(post?.uuid);
                              }}
                            >
                              Reject
                            </a>
                          </div>
                        </>
                      ) : post?.applicationStatus == "Approved" ? (
                        <>
                          <a
                            className="rejected"
                            onClick={(e) => {
                              rejectUser(post?.uuid);
                            }}
                          >
                            Reject
                          </a>
                        </>
                      ) : (
                        <>
                          <a
                            className="approve"
                            onClick={(e) => {
                              approveUser(post.uuid);
                            }}
                          >
                            Approve
                          </a>
                        </>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="d-flex justify-content-end mb-4 mt-4 align-items-center">
                    <div className="action-table">
                      {includesCreate ? (
                        post?.applicationStatus == "Pending" ? (
                          <></>
                        ) : post?.applicationStatus == "Approved" ? (
                          <>
                            {post?.stateLocalized == "Enabled" ? (
                              <a
                                className="rejected"
                                onClick={(e) => {
                                  blockUser(post?.uuid);
                                }}
                              >
                                Block
                              </a>
                            ) : (
                              <a
                                className="approve"
                                onClick={(e) => {
                                  unBlockUser(post?.uuid);
                                }}
                              >
                                UnBlock
                              </a>
                            )}
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <h2>
                    Full Name: <span>{post?.fullName}</span>
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>
                    Phone Number: <span>{post?.mobileNumber}</span>
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>
                    Available Days:{" "}
                    <span>
                      {post?.availableDaysLocalized &&
                        post?.availableDaysLocalized.join(", ")}
                    </span>
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>
                    City: <span>{post?.cityDisplayName}</span>
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>
                    Registration Day Count:{" "}
                    <span>{post?.registrationDayCount}</span>
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>
                    Active Subscription:{" "}
                    <span>{post?.hasActiveSubscription?.toString()}</span>
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>
                    Request Count: <span>{post?.requestCount}</span>
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>
                    Subscription Day Count:{" "}
                    <span>{post?.subscriptionDayCount}</span>
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>
                    Available Times:{" "}
                    <span>
                      {post?.availableTimesLocalized &&
                        post?.availableTimesLocalized.join(", ")}
                    </span>
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>
                    Specialties:
                    {post?.specialities &&
                      post?.specialities.map((x, index) => (
                        <span>
                          {index != 0 ? "," : ""} {x.specialityTitle}
                        </span>
                      ))}
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>
                    Email: <span>{post?.email}</span>
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>
                    State: <span>{post?.stateLocalized}</span>
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>
                    Price Per Hour: <span>{post?.pricePerHour}</span>
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>
                    Availability: <span>{post?.availability}</span>
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>
                    Experience: <span>{post?.experience}</span>
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>
                    Education: <span>{post?.education}</span>
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>
                    Tagline: <span>{post?.tagline}</span>
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>
                    Biography: <span>{post?.biography}</span>
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2 className="master-images">
                    Id Files:
                    <br />
                    {post?.idFiles &&
                      post?.idFiles.map((x, index) => (
                        <div className="delete-files">
                          <img src={`https://api.wabell.net${x}`} />
                          <a
                            className=""
                            onClick={(e) => {
                              deleteFiles(post.uuid, x);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </a>
                        </div>
                      ))}
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2 className="master-images">
                    Certificates:
                    <br />
                    {post?.certificates &&
                      post?.certificates.map((x, index) => (
                        <div className="delete-files">
                          <img src={`https://api.wabell.net${x}`} />
                          <a
                            className=""
                            onClick={(e) => {
                              deleteCertificates(post.uuid, x);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </a>
                        </div>
                      ))}
                  </h2>
                </div>
                <div className="col-md-4">
                  <h2>Profile Image:</h2>
                  <img
                    src={`https://api.wabell.net${post.profilePicturePath}`}
                    className="image-profile"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MasterDetails;
