import React, { useState, useEffect } from "react";
import "../Assets/Css/login.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
import { auth, provider } from "../firebaseConfig.js";
import { requestPermissionAndGetToken } from "../firebaseConfig.js";
import { signInWithPopup } from "firebase/auth";

function Login() {
  const [phoneOrEmail, setEmail] = useState();
  const [password, setPassword] = useState();
  // const [token, setToken] = useState();


  const auth = useAuth();

  // useEffect(() => {
  //   const getToken = async () => {
  //     const token = await requestPermissionAndGetToken();
  //     setToken(token)
  //     console.log('Token:', token);
  //   };

  //   getToken();
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (phoneOrEmail !== "" && password !== "") {
      auth.loginAction({
        phoneOrEmail: phoneOrEmail,
        password: password,
        // firebaseToken: token
      });
      return;
    }
  };


  return (
    <>
      <div className="login">
        <div className="login-card">
          <h1>Login</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
              <input
                className="form-control"
                id="phone"
                name="phoneOrEmail"
                type="text"
                placeholder="Phone Or Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="form-group mb-3">
              <input
                className="form-control"
                id="Password"
                name="Password"
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button className="submit" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
