import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import React, { useState, useEffect } from "react";
import "../Assets/Css/Table.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { axiosConfig } from "../axiosAPI";
import { Alert, Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";

function Vouchers() {
    const auth = useAuth();

    const handleChange2 = (e) => {
      const { name, value } = e.target;
      setPost({ ...post, [name]: value });
    };  
    const [post, setPost] = useState({
      freePackageDays: "",
    });
    const handlePost = async uuid => {
      const response = await axiosConfig
        .put(
          "https://api.wabell.net/api/v2/settings",
          {
            ...post,
            _method: "PUT",
          },
          {
            headers: {
                Authorization: "Bearer " + auth.token,
                "Content-Type": "multipart/form-data",
                "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
            },
          }
        )
        .then((res) => {
          console.log(res);
          setPost(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    useEffect(() => {
      axiosConfig
        .get("https://api.wabell.net/api/v2/settings", {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "multipart/form-data",
            "x-api-key": "a8k2j7h3pRn4wbL9xs5yo6uc1qe0fzmx",
          },
        })
        .then((response) => {
          setPost(response.data.data);
          console.log(response.data.data);
          console.log(post);
        });
    }, []);
    const [items, setItems] = useState([]);
  
    useEffect(() => {
      const items = localStorage.getItem('permissions');
      if (items) {
        setItems(items);
      }
    }, []);

    const Update = "p_s0002"; 
  
    // Check if targetValue is included in items
    const includesUpdate = items.includes(Update);


  return (
    <>
        <div className="table-card">
            <form className="General">
                <div className="row">
                    <div className="col-md-6 mt-3">
                        <div className="form-group">
                            <label>Free Package Days</label>
                            <input
                                type="number"
                                value={post?.freePackageDays}
                                onChange={handleChange2}
                                id="freePackageDays"
                                name="freePackageDays"
                            />
                        </div>
                    </div>
                    <div className="col-md-12 text-end mt-5">
                        {includesUpdate ? <a className="save" href="#!" onClick={() => { handlePost();}}>Save</a> : "" }
                    </div>
                </div>
            </form>
        </div>
    </>
  );
}

export default Vouchers;
