import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import 'firebase/compat/auth'; // Import Firebase Authentication if needed

const firebaseConfig = {
  apiKey: "AIzaSyD_rGp_BPWfX0aBa9ROrJDrgJtP8QM7sE8",
  authDomain: "wabell-f335d.firebaseapp.com",
  databaseURL: "https://wabell-f335d.firebaseio.com",
  projectId: "wabell-f335d",
  storageBucket: "wabell-f335d.appspot.com",
  messagingSenderId: "906829148222",
  appId: "1:906829148222:web:3598d06cef0048a414616f",
  measurementId: "G-95TB7TS0CN"
};

firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

export const requestPermissionAndGetToken = async () => {
  try {
    // Request notification permission
    const permission = await Notification.requestPermission();
    console.log(permission);
    if (permission === 'granted') {
      // Ensure the user is authenticated (optional)
      // const user = firebase.auth().currentUser;
      // if (!user) {
      //   console.log('User is not authenticated');
      //   return null;
      // }

      const token = await messaging.getToken({ 
        vapidKey: 'BCm5IZyPvNAJnCe5JozqzxwsNg85tQnsWjskha4Ww4UBdG_pEBUDnzybTri00tV4SqJd7_mYgXB5BtydAje4JfE' 
      });

      console.log('FCM Token received:', token);
      return token;
    } else {
      console.log('Notification permission not granted.');
      return null;
    }
  } catch (error) {
    console.error('Error getting token:', error);
    return null;
  }
};

export default messaging;